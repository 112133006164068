<template>
  <div class="min-h-screen justify-center bg-white flex dark:bg-primaryDark dark:bg-backgroundDark  bg-background">
    <div  class="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
      <div class="mx-auto w-full sm:max-w-md bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10 dark:bg-primaryDark">
        <success :message="alertMessage" v-if="showSuccess" />
        <danger :message="alertMessage" v-if="showDanger" />  
        <h1 class="text-3xl font-extrabold text-gray-900 dark:text-white">
          Reset Password
      </h1>
      <p class="text-l dark:text-white">You can reset your password using this form</p>
     
      <div class="">
        <form @submit.prevent="submit" >  
        <div class="mt-3">
          <div>
          <div class="space-y-1">
            <label for="password" class="block text-sm font-medium text-gray-700 dark:text-white">
                      New Password
            </label>
            <input type="password" v-model="state.password1" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:text-white dark:bg-primaryDark" placeholder=""/>
          </div>
          <div :class="{ error: v$.password1.$errors.length }">
            <div class="input-errors" v-for="error of v$.password1.$errors" :key="error.$uid">
              <div class="error-msg text-red-600" v-if="error.$validator==='required'">Password is required</div>
              <div class="error-msg text-red-600" v-if="error.$validator==='minLength'">Password must be minimum 8 characters</div>
            </div>
          </div>
          </div>
          <div class="mt-3">
          <div class="space-y-1">
            <label for="password" class="block text-sm font-medium text-gray-700 dark:text-white">
                      Confirm New Password
            </label>
            <input type="password" v-model="state.password2" class="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm dark:text-white dark:bg-primaryDark" placeholder=""/>
          </div>
          <div :class="{ error: v$.password2.$errors.length }">
            <div class="input-errors" v-for="error of v$.password2.$errors" :key="error.$uid">
               <div class="error-msg text-red-600" v-if=" error.$validator==='areEqual'">Password and Confirm Password should match</div>
            </div>
          </div>
        </div>
          <div class="mt-3">

            <button type="submit" class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gray-600 hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 dark:bg-backgroundDark dark:text-white">Reset password</button>
          </div>
       
        </div>
      </form>
      </div>
      </div>
    </div>

  </div>
</template>

<script>
import { reactive} from 'vue'
import { useStore } from "vuex";
import useVuelidate from '@vuelidate/core'
import { required, minLength,helpers  } from '@vuelidate/validators'
import success from '@/components/widgets/alertMessage/success'
import danger from '@/components/widgets/alertMessage/danger'


export default {
  name: "ResetPassword",
  components: { success,danger },
  data() {
        return {
          alertMessage: "",
          showSuccess:false,
          showDanger:false
        }
    },
  setup() {
    const store = useStore()
    const state = reactive({
      password1: '',
      password2: ''
    })

    const areEqual = (pw1Func) => (pw2) => {
  const pw1 = pw1Func()
  return pw1 === pw2
}


    const rules = {
      password1: { required, minLength: minLength(8) },
      password2: { required,areEqual: helpers.withMessage('Passwords must match', areEqual(() => state.password1)) }
    }
    const v$ = useVuelidate(rules, state, { $lazy: false })
   
    return {
      v$,
      store,
      state    
    }
  },
  methods: {
    async  submit() {
      this.showSuccess=false;
              this.showDanger=false;
      this.v$.$touch()
      if (this.v$.$error) return
        // store.dispatch("ResetPassword", {password1: pw1.value, password2: pw2.value, token: route.params.token})
        // this.$router.push({ name: "Home" })

        try {
            await this.store.dispatch('ResetPassword',{password1:this.state.password1, password2:this.state.password2, token: this.$route.params.token})
                .then(response => {
                  if ( response.success === true || response.success === 'true' ) {
                      console.log(response)
                      let message ='Your password has successfully been reset. Please log in to continue.'
                      let success = response.success ;
                      this.$router.push({ name: "Login",params: {success,message} }); 
                      
                    }else{
                      console.log(response.message)
                      this.alertMessage=response.message
                      this.showDanger=true;
                      }
            }).catch(error => {
            
              this.alertMessage=error
              this.showDanger=true;
            });
         } catch (error) {
       
          this.alertMessage=error
          this.showDanger=true;
        }
       
      }
  }
}
</script>